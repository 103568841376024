import { FunctionComponent } from 'react';

import { defaultStyles } from '@/helper/defaultStyles';
import PortfolioCard from './PortfolioCard';
import CooperationCard from './CooperationCard';
import AboutCard from './AboutCard';

const PortfolioSection: FunctionComponent = () => {
  return (
    <section
      className={`${defaultStyles.paddingBig} flex flex-col gap-12`}
      id="portfolio"
    >
      <PortfolioCard />
      <CooperationCard />
      <AboutCard />
    </section>
  );
};

export default PortfolioSection;
