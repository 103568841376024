import { FunctionComponent } from 'react';

import AboutSection from '@/components/home/AboutSection';
import PortfolioSection from '@/components/home/PortfolioSection';
import Head from 'next/head';
import HeroSection from '../components/home/HeroSection';

const Home: FunctionComponent = () => {
  return (
    <div className="w-full overflow-hidden">
      <Head>
        <title>Likepage</title>
        <meta name="description" content="Strona główna Likepage" />
      </Head>
      <HeroSection />
      <AboutSection />
      <PortfolioSection />
    </div>
  );
};

export default Home;
