import { defaultStyles } from '@/helper/defaultStyles';
import Button from '../Button';
import DecorativeHeader from './DecorativeHeader';
import createStyleForBackgroundImage from '@/helper/createStyleForBackgroundImage';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { PortfolioResponse } from '@/pages/portfolio';
import { useState } from 'react';

const data = [
  {
    id: 1,
    imgUrl: '/portfolio/origami.webp',
    name: 'Origami.pl',
    url: 'Origami.pl',
  },
  {
    id: 2,
    imgUrl: '/portfolio/ggdab.webp',
    name: 'GGDAB.pl',
    url: 'GGDAB.PL',
  },
  {
    id: 3,
    imgUrl: '/portfolio/dayofduel.webp',
    name: 'Day of Duel.pl',
    url: 'Day of Duel.pl',
  },
];

function Card(props: {
  title: string;
  image: string;
  value: string;
  fullImgUrl?: string;
  videoUrl?: string;
  class?: string;
  isSmall?: boolean;
  tag: number;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div
        className={`min-h-[302px] max-h-[302px] flex flex-col justify-end items-start p-4 rounded-[41px] ${props.class}`}
        style={createStyleForBackgroundImage(props.image)}
      >
        <div className="flex flex-col gap-2">
          {props.value && props.title && (
            <div className="p-4 flex justify-center items-center text-center bg-[#0A1722] hover:bg-primary duration-300 transition-all cursor-pointer rounded-[56px] max-w-[260px]">
              <div
                className="text-default text-[16px] font-normal cursor-pointer"
                onClick={() => window.open(props.value, '_blank')}
              >
                {props.title}
              </div>
            </div>
          )}
          <Button label="Sprawdź" onClick={() => setIsModalOpen(true)} />
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed top-0 left-0 flex justify-center items-center backdrop-blur-lg w-full h-full">
          <div
            className="absolute flex flex-col justify-center items-center top-2 right-2 h-8 w-8 rounded-full bg-slate-400 text-black cursor-pointer hover:bg-primary hover:text-default transition-all duration-300"
            onClick={() => setIsModalOpen(false)}
          >
            ✖
          </div>
          <div className="h-[90%] w-[90%] xl:w-[80%] flex justify-center items-center overflow-y-auto overflow-x-hidden">
            {props.fullImgUrl && (
              <div className="h-full">
                <img
                  className={`${
                    props.tag == 7 ? 'lg:h-[90%]' : 'h-auto'
                  } w-full`}
                  loading="lazy"
                  alt={'portfolio ' + props.title}
                  src={props.fullImgUrl}
                />
              </div>
            )}
            {props.videoUrl && (
              <video src={props.videoUrl} className="h-full w-full" controls />
            )}
          </div>
        </div>
      )}
    </>
  );
}

function PortfolioCard() {
  const { data } = useQuery({
    queryKey: ['PORTFOLIO_LIST'],
    queryFn: () =>
      axios
        .get('https://api.likepage.eu/portfolios/all/?page=1')
        .then((r) => r.data as PortfolioResponse),
    staleTime: 60 * 1000,
  });

  return (
    <div
      className={`${defaultStyles.padding} flex flex-col gap-8 pt-12 items-start rounded-[69px]`}
      style={createStyleForBackgroundImage('/portfolio-gradient.webp')}
    >
      <div className="flex flex-col-reverse lg:flex-row gap-8 items-start">
        <div className="font-extrabold text-[25px] lg:text-[55px] text-default">
          Portfolio
        </div>
        <DecorativeHeader label="Portfolio" class="bg-[#162E4419] px-12" />
      </div>
      <div className="flex flex-col lg:flex-row gap-8 justify-between lg:items-end">
        <div className="font-light text-[16px] text-default lg:w-1/2">
          Likepage.com to innowacyjna firma, która dostarcza kompleksowe
          rozwiązania w zakresie projektowania stron internetowych,
        </div>
        <Button
          label="Zobacz więcej"
          class="mt-8 w-[160px]"
          onClick={() => window.location.assign('/portfolio')}
        />
      </div>
      {data?.results && (
        <div className="flex flex-col lg:flex-row gap-4 w-full">
          <div className="flex flex-col gap-4 lg:w-1/2">
            <Card {...data.results[0]} />
            <Card {...data.results[1]} />
          </div>
          <div className="flex flex-col gap-4 lg:w-1/2">
            <Card {...data.results[2]} />
            <Card {...data.results[3]} />
          </div>
        </div>
      )}
    </div>
  );
}

export default PortfolioCard;
