import { defaultStyles } from '@/helper/defaultStyles';
import DecorativeHeader from './DecorativeHeader';

const data = [
  {
    id: 1,
    title: 'Autorskie integracje zewnętrznych platform (np. płatności)',
  },
  {
    id: 2,
    title: 'Projekt graficzny w 24h',
  },
  {
    id: 3,
    title: 'Stworzenie podstawowej strony lub sklepy do 3 dni roboczych',
  },
  {
    id: 4,
    title: 'Darmowa konsultacja',
  },
  {
    id: 5,
    title: 'Natychmiastowe rozpoczęcie współpracy po omówieniu szczegółów',
  },
  {
    id: 6,
    title: 'Indywidualny konsultant dla każdego klienta',
  },
];

function Line(props: { title: string; id: number }) {
  return (
    <div
      className={`flex flex-row justify-between gap-4 min-h-[80px] ${
        props.id != 1 &&
        'border border-dotted border-[#1B263D] border-t border-b-0 border-r-0 border-l-0'
      } pl-4`}
      key={props.id}
    >
      <div className="font-light text-[16px] text-default py-4 self-center">
        {props.title}
      </div>
      <div className="flex flex-row">
        <div
          className={`bg-[#0057FF] ${props.id == 1 && 'rounded-t-[30px]'} ${
            props.id == 6 && 'rounded-b-[30px]'
          } flex flex-col justify-center items-center w-[70px] md:w-[100px] lg:w-[160px]`}
        >
          <img
            className="w-[36px] h-[36px]"
            src="/icon/check-positive.svg"
            alt="positive check"
            loading="lazy"
          />
        </div>
        <div className="flex flex-col justify-center items-center w-[70px] md:w-[100px] lg:w-[160px]">
          <img
            className="w-[36px] h-[36px]"
            src="/icon/check-negative.svg"
            alt="negative check"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
}

function CooperationCard() {
  return (
    <div
      className={`${defaultStyles.padding} flex flex-col 2xl:flex-row gap-12 xl:gap-8 justify-center items-start md:items-center py-12`}
    >
      <div className="flex flex-col gap-4 2xl:w-[30%]">
        <DecorativeHeader label="Dlaczego my?" class="bg-[#060E14]" />
        <div className="font-extrabold text-[35px] lg:text-[45px] text-default">
          Zalety współpracy z nami
        </div>
        <div className="font-light text-[16px] text-default">
          Likepage.com to innowacyjna firma, która dostarcza kompleksowe
          rozwiązania w zakresie projektowania stron internetowych, sklepów
          online, a także usług związanych z audytem i poprawą wydajności
          istniejących witryn. Specjalizujemy się w tworzeniu autorskich wtyczek
          i systemów zarządzania treścią (CMS/CRM), które są dostosowane do
          unikalnych potrzeb każdego klienta, ułatwiają zarządzanie firmą i
          zwiększają jej efektywność operacyjną.
        </div>
      </div>
      <div className="flex flex-col gap-2 2xl:w-[70%]">
        <div className="flex flex-row self-end gap-4 md:gap-[22px] lg:gap-[82px] md:mr-[10px] lg:mr-[40px]">
          <div className="font-bold text-[16px] text-default">Likepage</div>
          <div className="font-bold text-[16px] text-default">Inne firmy</div>
        </div>
        <div className="rounded-[30px] border border-solid border-[#1B263D]">
          {data.map((item) => (
            <Line {...item} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CooperationCard;
