import createStyleForBackgroundImage from '@/helper/createStyleForBackgroundImage';
import { useState } from 'react';
import Button from '../Button';
import ContactCard from '../contact/ContactCard';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function HeroTitleCard() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const data = [
    {
      title: (
        <>
          Czy Twoja strona internetowa lub sklep online nie spełnia oczekiwań?
        </>
      ),
      desc: (
        <>
          Nasza oferta to kompleksowy audyt techniczny i SEO, który identyfikuje
          i eliminuje błędy w kodzie.
        </>
      ),
      button: 'Darmowa konsultacja',
      imageUrl: '/hero-bg.webp',
    },
    {
      title: (
        <>Zintegrujemy dla Ciebie płatności oraz kurierów z twoim sklepem!</>
      ),
      desc: (
        <>
          Posiadamy autorskie rozwiązania umożliwiające integrację płatności
          oraz kurierów z praktycznie każdym CMS!
        </>
      ),
      button: 'Zadzwoń lub napisz już teraz!',
      imageUrl: '/hero-bg-2.webp',
    },
  ];

  const Slide = (props: {
    title: JSX.Element;
    desc: JSX.Element;
    button: string;
    index: number;
    imageUrl: string;
  }) => {
    return (
      <div
        className="flex flex-col gap-4 justify-center items-center rounded-[41px] min-h-[340px] lg:min-h-[400px] bg-left lg:bg-center"
        style={createStyleForBackgroundImage(props.imageUrl, {
          withDarkOverlay: true,
          backgroundPosition: 'left',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        })}
      >
        <div
          className="flex flex-col gap-4 justify-center items-center w-full"
          key={props.index}
        >
          <div className="flex flex-col gap-4 justify-center items-center md:rounded-[41px] p-2">
            <div className="font-bold text-[18px] lg:text-[35px] text-default text-center lg:w-[45%]">
              {props.title}
            </div>
            <div className="font-normal text-[14px] lg:text-[18px] text-default text-center lg:w-[45%]">
              {props.desc}
            </div>
            <Button
              label={props.button}
              class="mt-8 self-center"
              secondary
              onClick={() => setIsModalOpen(true)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="lg:px-24 xl:px-32 2xl:px-52">
      <Carousel
        responsive={responsive}
        className="py-12"
        slidesToSlide={1}
        arrows={false}
        autoPlay
        autoPlaySpeed={4000}
        infinite
        showDots
      >
        {data.map((item, index) => (
          <Slide {...item} index={index} />
        ))}
      </Carousel>
      {isModalOpen && (
        <div className="fixed w-[100%] h-[100%] top-0 left-0 backdrop-blur-lg z-40 flex flex-col justify-center items-center">
          <div className="w-[90%] lg:w-[60%] lg:h-[80%] z-50 flex flex-col justify-center items-center">
            <ContactCard onClick={() => setIsModalOpen(false)} />
          </div>
        </div>
      )}
    </div>
  );
}

export default HeroTitleCard;
