import { defaultStyles } from '@/helper/defaultStyles';
import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import DecorativeHeader from './DecorativeHeader';
import createStyleForBackgroundImage from '@/helper/createStyleForBackgroundImage';

const data = [
  {
    id: 1,
    title: 'Projektowanie',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque elementum at neque quis venenatis. Vestibulum posuere accumsan massa, eget bibendum lectus imperdiet in. Mauris lacinia sagittis',
    imgUrl: '/services-1.webp',
    color: 'text-[#0057FF]',
    svgUrl: '/icon/services/services-1.svg',
    primaryBg: 'bg-[#0057FF]',
    secondaryBg: 'bg-[#0057FF18]',
  },
  {
    id: 2,
    title: 'Tworzenie stron',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque elementum at neque quis venenatis. Vestibulum posuere accumsan massa, eget bibendum lectus imperdiet in. Mauris lacinia sagittis',
    imgUrl: '/services-2.webp',
    color: 'text-[#892CFF]',
    svgUrl: '/icon/services/services-2.svg',
    primaryBg: 'bg-[#892CFF]',
    secondaryBg: 'bg-[#892CFF18]',
  },
  {
    id: 3,
    title: 'Tworzenie sklepów',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque elementum at neque quis venenatis. Vestibulum posuere accumsan massa, eget bibendum lectus imperdiet in. Mauris lacinia sagittis',
    imgUrl: '/services-3.webp',
    color: 'text-[#FB2CFF]',
    svgUrl: '/icon/services/services-3.svg',
    primaryBg: 'bg-[#FB2CFF]',
    secondaryBg: 'bg-[#FB2CFF18]',
  },
  {
    id: 4,
    title: 'Optymalizacja SEO',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque elementum at neque quis venenatis. Vestibulum posuere accumsan massa, eget bibendum lectus imperdiet in. Mauris lacinia sagittis',
    imgUrl: '/services-4.webp',
    color: 'text-[#FF505A]',
    svgUrl: '/icon/services/services-4.svg',
    primaryBg: 'bg-[#FF505A]',
    secondaryBg: 'bg-[#FF505A18]',
  },
  {
    id: 5,
    title: 'Audyt i poprawa istniejących stron',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque elementum at neque quis venenatis. Vestibulum posuere accumsan massa, eget bibendum lectus imperdiet in. Mauris lacinia sagittis',
    imgUrl: '/services-5.webp',
    color: 'text-[#FFE074]',
    svgUrl: '/icon/services/services-5.svg',
    primaryBg: 'bg-[#FFE074]',
    secondaryBg: 'bg-[#FFE07418]',
  },
  {
    id: 6,
    title: 'Optymalizacja wydajności stron',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque elementum at neque quis venenatis. Vestibulum posuere accumsan massa, eget bibendum lectus imperdiet in. Mauris lacinia sagittis',
    imgUrl: '/services-6.webp',
    color: 'text-[#93E36E]',
    svgUrl: '/icon/services/services-6.svg',
    primaryBg: 'bg-[#93E36E]',
    secondaryBg: 'bg-[#93E36E18]',
  },
];

function Card(props: {
  title: string;
  desc: string;
  id: number;
  imgUrl: string;
  color: string;
  svgUrl: string;
  primaryBg: string;
  secondaryBg: string;
}) {
  return (
    <div
      className="flex flex-col justify-center items-center rounded-[41px] min-w-[300px] max-w-[300px] lg:min-w-[320px] lg:max-w-[320px] xl:min-w-[370px] xl:max-w-[370px] "
      key={props.id}
    >
      <div
        className="w-full rounded-t-[41px] flex flex-col justify-center items-center"
        style={createStyleForBackgroundImage(props.imgUrl)}
      >
        <div
          className={`w-[175px] h-[175px] flex flex-col justify-center items-center rounded-full ${props.secondaryBg}`}
        >
          <div
            className={`w-[119px] h-[119px] flex flex-col justify-center items-center rounded-full ${props.primaryBg}`}
          >
            <img
              alt="icon"
              width="68"
              height="68"
              loading="lazy"
              src={props.svgUrl}
            />
          </div>
        </div>
      </div>
      <div className="bg-[#0A1722] flex flex-col gap-4 w-full rounded-b-[41px] pt-4 pb-8">
        <div className="flex flex-row justify-between items-center px-4">
          <div className="font-bold text-[22px] text-default">
            {props.title}
          </div>
          <div className="min-w-[52px] max-w-[52px] min-h-[52px] max-h-[52px] rounded-full flex flex-col justify-center items-center bg-[#122738]">
            <div
              className={`${props.color} text-[16px] font-bold tracking-[0.3em] ml-[4px]`}
            >
              {'0' + props.id}
            </div>
          </div>
        </div>
        <div className="font-normal text-[16px] text-[#FFFFFF19] px-4">
          {props.desc}
        </div>
      </div>
    </div>
  );
}

function ServicesCard() {
  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef<HTMLDivElement | null>(null);
  const [itemMarker, setItemMarker] = useState(1);
  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
      setItemMarker(itemMarker - 1);
    }
  };

  const moveNext = () => {
    if (carousel.current !== null) {
      if (currentIndex < data.length - 1) {
        setCurrentIndex((prevState) => prevState + 1);
        setItemMarker(itemMarker + 1);
      }
    }
  };

  useEffect(() => {
    if (carousel.current !== null) {
      carousel.current.scrollLeft =
        (carousel.current.offsetWidth * currentIndex) / (isMobile ? 1 : 2);
    }
  }, [currentIndex, carousel]);

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0;
  }, []);

  const containerWidth = '100%';

  const svgClass =
    'cursor-pointer stroke-default hover:stroke-primary transition-all duration-300';

  return (
    <div
      className={`${defaultStyles.padding} flex flex-col 2xl:flex-row gap-12 2xl:gap-8 justify-center items-center py-12 rounded-[41px] bg-[#060D13]`}
      id="service"
    >
      <div className="flex flex-col gap-4 2xl:w-1/3">
        <DecorativeHeader label="Kim jesteśmy?" class="bg-[#0A1722]" />
        <div className="font-extrabold text-[25px] lg:text-[55px] text-default">
          Usługi, które oferujemy
        </div>
        <div className="font-light text-[16px] text-default">
          Likepage.com to innowacyjna firma, która dostarcza kompleksowe
          rozwiązania w zakresie projektowania stron internetowych, sklepów
          online
        </div>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row gap-2 items-center">
            <div className="font-bold text-[25px] text-default">
              0{itemMarker}
            </div>
            <div className="text-[16px] text-[#1D3F5C]">/ 0{data.length}</div>
          </div>
          <div className="flex flex-row gap-4">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={movePrev}
              className={svgClass}
            >
              <path
                d="M15 6L9 12L15 18"
                className="stroke-inherit"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={moveNext}
              className={svgClass}
            >
              <path
                d="M9 18L15 12L9 6"
                className="stroke-inherit"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4">
        <div className="carousel flex flex-row items-center justify-center">
          <div className="relative overflow-hidden flex flex-col lg:justify-center lg:items-center">
            <div
              ref={carousel}
              className={`carousel-container relative w-full flex overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-0 max-w-[300px] lg:gap-2 lg:max-w-[656px] xl:max-w-[756px]`}
              style={{ width: containerWidth }}
            >
              {data.map((item) => (
                <Card {...item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesCard;
