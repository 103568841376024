import { defaultStyles } from '@/helper/defaultStyles';
import HeroItemsGroupItem from './HeroItemsGroupItem';

const data = [
  {
    title: 'Gwarancja',
    desc: 'Przez 2 lata od stworzenia strony dajemy Ci gwarancję jej bezblędnego działania.',
    imageUrl: '/icon/hero-warranty.svg',
    divClass: 'bg-[#FB2CFF]',
  },
  {
    title: 'Skuteczność',
    desc: 'Tworzymy strony tak by miały wysoką konwersję oraz optymalizujemy jej widoczność w cenie.',
    imageUrl: '/icon/hero-effective.svg',
    divClass: 'bg-[#892CFF]',
  },
  {
    title: 'Prędkość',
    desc: 'Zajmujemy się zleceniami od ręki, więc Twoja strona może być gotowa już w 2 dni.',
    imageUrl: '/icon/hero-speed.svg',
    divClass: 'bg-[#0500FF]',
  },
];

function HeroItemsGroup() {
  return (
    <div
      className={`${defaultStyles.paddingBig} grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 justify-center items-start mt-12`}
    >
      {data.map((item, index) => (
        <HeroItemsGroupItem {...item} index={index} />
      ))}
    </div>
  );
}

export default HeroItemsGroup;
