import createStyleForBackgroundImage from '@/helper/createStyleForBackgroundImage';
import Button from '../Button';
import { defaultStyles } from '@/helper/defaultStyles';
import DecorativeHeader from './DecorativeHeader';
import { useState } from 'react';
import ContactCard from '../contact/ContactCard';

function AboutCard() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div
        className={`${defaultStyles.padding} flex flex-col xl:flex-row gap-12 xl:gap-8 justify-center items-center py-12 rounded-[41px]`}
        style={createStyleForBackgroundImage('/about-bg.webp')}
        id="about"
      >
        <div className="flex flex-col gap-4 xl:w-1/2">
          <DecorativeHeader
            label="Kim jesteśmy?"
            class="backdrop:blur-md bg-[#07101819]"
          />
          <div className="font-extrabold text-[25px] lg:text-[55px] text-default">
            Troche słów o Likepage.pl
          </div>
          <div className="font-light text-[16px] text-default">
            Likepage.com to innowacyjna firma, która dostarcza kompleksowe
            rozwiązania w zakresie projektowania stron internetowych, sklepów
            online, a także usług związanych z audytem i poprawą wydajności
            istniejących witryn. Specjalizujemy się w tworzeniu autorskich
            wtyczek i systemów zarządzania treścią (CMS/CRM), które są
            dostosowane do unikalnych potrzeb każdego klienta, ułatwiają
            zarządzanie firmą i zwiększają jej efektywność operacyjną.
          </div>
          <Button
            label="Skontaktuj się"
            class="mt-8 w-[160px]"
            onClick={() => setIsModalOpen(true)}
          />
        </div>
        <img
          className="w-[90%] md:w-[60%] xl:w-[50%]"
          src="/about.webp"
          alt="about us"
          loading="lazy"
        />
      </div>

      {isModalOpen && (
        <div className="fixed w-[100%] h-[100%] top-0 left-0 backdrop-blur-lg z-40 flex flex-col justify-center items-center">
          <div className="w-[90%] lg:w-[70%] lg:h-[70%] z-50 flex flex-col justify-center items-center">
            <ContactCard onClick={() => setIsModalOpen(false)} />
          </div>
        </div>
      )}
    </>
  );
}

export default AboutCard;
