import { FunctionComponent } from 'react';

import HeroItemsGroup from './HeroItemsGroup';
import HeroTitleCard from './HeroTitleCard';

const HeroSection: FunctionComponent = () => {
  return (
    <section className="flex flex-col">
      <HeroTitleCard />
      <HeroItemsGroup />
    </section>
  );
};

export default HeroSection;
