function HeroItemsGroupItem(props: {
  imageUrl: string;
  title: string;
  desc: string;
  index: number;
  divClass?: string;
}) {
  return (
    <div
      className="flex flex-col justify-center items-center text-center gap-4"
      key={props.index}
    >
      <div
        className={`w-[138px] h-[138px] rounded-[41px] flex flex-col justify-center items-center ${props.divClass}`}
      >
        <img
          width="62"
          height="62"
          alt="icon"
          loading="lazy"
          src={props.imageUrl}
        />
      </div>
      <div className="text-[35px] font-bold">{props.title}</div>
      <div className="w-[80%]">{props.desc}</div>
    </div>
  );
}

export default HeroItemsGroupItem;
