function DecorativeHeader(props: { label: string; class: string }) {
  return (
    <div
      className={`flex flex-row items-center justify-center gap-4 rounded-[66px] max-w-[286px] px-4 py-6 ${props.class}`}
    >
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.9733 1.00012L7.00728 2.96614C6.44902 3.52439 5.53883 3.52439 4.98058 2.96614L3.02063 1.00619C2.46844 0.441872 1.55825 0.441872 0.999998 1.00012C0.441746 1.55838 0.441746 2.46857 0.999998 3.02682L2.96602 4.99284C3.52427 5.55109 3.52427 6.46129 2.96602 7.01954L1.00607 8.97949C0.441746 9.53167 0.441745 10.4419 0.999998 11.0001C1.55825 11.5584 2.46844 11.5584 3.0267 11.0001L4.99271 9.0341C5.55097 8.47585 6.46116 8.47585 7.01941 9.0341L8.98543 11.0001C9.54368 11.5584 10.4539 11.5584 11.0121 11.0001C11.5704 10.4419 11.5704 9.53167 11.0121 8.97342L9.04611 7.0074C8.48786 6.44915 8.48786 5.53896 9.04611 4.9807L11.0121 3.01469C11.5704 2.45643 11.5704 1.54624 11.0121 0.987988C10.4417 0.441872 9.53155 0.441871 8.9733 1.00012Z"
          fill="#FBF3E6"
        />
      </svg>
      <div className="font-bold text-[12px] tracking-[0.3em] uppercase">
        {props.label}
      </div>
    </div>
  );
}

export default DecorativeHeader;
