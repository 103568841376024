import { FunctionComponent } from 'react';

import { defaultStyles } from '@/helper/defaultStyles';
import AboutCard from './AboutCard';
import ServicesCard from './ServicesCard';
import MultiItemCarousel from '../MultiItemCarousel';

const data = [
  {
    id: 1,
    url: '/tech/shopify.webp',
  },
  {
    id: 2,
    url: '/tech/wp.webp',
  },
  {
    id: 3,
    url: '/tech/node.webp',
  },
  {
    id: 4,
    url: '/tech/react.webp',
  },
  {
    id: 5,
    url: '/tech/python.webp',
  },
  {
    id: 6,
    url: '/tech/bootstrap.webp',
  },
  {
    id: 7,
    url: '/tech/php.webp',
  },
  {
    id: 8,
    url: '/tech/strapi.webp',
  },
  {
    id: 9,
    url: '/tech/shoper.webp',
  },
  {
    id: 10,
    url: '/tech/magento.webp',
  },
  {
    id: 11,
    url: '/tech/presta.webp',
  },
  {
    id: 12,
    url: '/tech/shopify.webp',
  },
];

function Slide(props: { url: string; index: number }) {
  return (
    <div
      key={props.index}
      className="p-4 flex flex-row justify-center items-center min-w-[122px] max-w-[122px] h-[122px]"
    >
      <img src={props.url} width="122" height="122" alt="slide" />
    </div>
  );
}

const AboutSection: FunctionComponent = () => {
  return (
    <section className={`${defaultStyles.paddingBig} flex flex-col gap-8`}>
      <ServicesCard />
      <MultiItemCarousel
        data={data}
        class="gap-8 !w-[85%] self-center"
        arrowsClass="hidden"
        autoScroll
        elementWidth={186}
      >
        {data.map((item, index) => (
          <Slide {...item} index={index} />
        ))}
      </MultiItemCarousel>
    </section>
  );
};

export default AboutSection;
